import React, { useEffect } from "react"
import Head from "next/head"
import AemGrid from "@/components/AemGrid"
import {
  MODEL_JSON_EXTENSION,
  footerUrl,
  FRAGMENT_TYPES,
  headerUrl,
  previewContentPrefix,
} from "@/constants"
import { aemAxiosInstance, aemPreviewAxiosInstance } from "@/constants/api"
import { useDispatch, useSelector } from "react-redux"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { i18n } from "next-i18next"
import { filterBackEndJson, getClienIP } from "@/utils/helper"
import ProductAccordion from "@/components/ProductDetail/v3/ProductAccordion"
import BreadCrumb from "@/components/Default/BreadCrumb"
import {
  selectGenericState,
  setGbhDataLayerCustom,
} from "@/store/features/genericSlice"
import usePageAuth from "@/hooks/usePageAuth"
import { getDataLayerObj } from "@/utils/helper"
import GlobalPageHeader from "@/components/Shared/GlobalPage/GlobalPageHeader"
import GlobalPageFooter from "@/components/Shared/GlobalPage/GlobalPageFooter"
import UpsellInstallServices from "@/components/Shared/UpsellInstallServices"
import Script from "next/script"
/**
 * renders home page
 * @return {JSX.Element}
 */
function Home({
  tmpData: data,
  headertmp: header,
  footertmp: footer,
  ip,
  globalScript,
  footerScript,
  analyticsData,
  gbhDataLayerCustom,
  homeApplicationJSONSchema,
  seoProperties,
}) {
  const dispatch = useDispatch()
  const { brandLogoImg } = useSelector(selectGenericState)

  useEffect(() => {
    if (ip) sessionStorage.setItem("true-ip", ip)
  }, [ip])
  usePageAuth()
  useEffect(() => {
    getDataLayerObj(analyticsData)
  }, [analyticsData])
  useEffect(() => {
    dispatch(setGbhDataLayerCustom(gbhDataLayerCustom))
    return () => dispatch(setGbhDataLayerCustom(""))
  }, [])

  return (
    <>
      <Head>
        {data?.description && (
          <>
            <meta name="description" content={data?.description} />
            <meta property="og:description" content={data?.description} />
          </>
        )}

        {homeApplicationJSONSchema ? (
          <script type="application/ld+json">
            {homeApplicationJSONSchema}
          </script>
        ) : null}
      </Head>
      <>
        <GlobalPageHeader
          header={header}
          title={data.title}
          globalScripting={globalScript}
          fullUrl={typeof window !== "undefined" && window.location.href}
          phone={seoProperties?.phoneNum}
          imageUrl={
            typeof window !== "undefined" &&
            window.location.origin + brandLogoImg
          }
        />
        <AemGrid
          data={data}
          count={0}
          fragment={FRAGMENT_TYPES.MAIN}
          breadCrumb={(data, fullData) => (
            <BreadCrumb data={data} fullData={fullData} />
          )}
          productAccordion={data => <ProductAccordion data={data} />}
          upSellContainer={data => <UpsellInstallServices authData={data} />}
        />
        <GlobalPageFooter footer={footer} footerScripting={footerScript} />
        <Script id="disableScrollRestoration">{`window.history.scrollRestoration = "manual"`}</Script>
      </>
    </>
  )
}
/**
 *
 * @return {Object}
 */
export async function getServerSideProps({ req, res, locale }) {
  const isPreviewMode = req.cookies["aem-author-preview-mode"]
  await i18n?.reloadResources()
  const ip = getClienIP(req)
  const urlPath = req.url.split("?")[0] ?? ""
  const { data } = isPreviewMode
    ? await aemPreviewAxiosInstance({
        url: previewContentPrefix + `/${locale}${MODEL_JSON_EXTENSION}`,
        method: "GET",
      })
    : await aemAxiosInstance({
        url: `/${locale}${MODEL_JSON_EXTENSION}`,
        method: "GET",
        headers: {
          IpAddress: ip,
        },
      })
  const seoProperties = data?.seoProperties

  const { data: header } = isPreviewMode
    ? await aemPreviewAxiosInstance({
        url: `${data.headerExpFrg ? data.headerExpFrg : headerUrl}.model.json`,
        method: "GET",
      })
    : await aemAxiosInstance({
        url: `${data.headerExpFrg ? data.headerExpFrg : headerUrl}.model.json`,
        method: "GET",
        headers: {
          IpAddress: ip,
        },
      })
  const { data: footer } = isPreviewMode
    ? await aemPreviewAxiosInstance({
        url: `${data.footerExpFrg ? data.footerExpFrg : footerUrl}.model.json`,
        method: "GET",
      })
    : await aemAxiosInstance({
        url: `${data.footerExpFrg ? data.footerExpFrg : footerUrl}.model.json`,
        method: "GET",
        headers: {
          IpAddress: ip,
        },
      })
  const globalScript = data?.globalScript
  const footerScript = data?.footerScript
  const topHeadScript = data?.topHeadScript ? data?.topHeadScript : null
  const analyticsScriptClass = data?.analyticsScriptClass
    ? data?.analyticsScriptClass
    : null

  let tmpData = {}
  if (data.pageComponentProperties) {
    tmpData = data.pageComponentProperties
  } else {
    tmpData = data
  }
  const analyticsData = data?.data
  const gbhDataLayerCustom = {}
  if (data?.datalayerPageName) {
    gbhDataLayerCustom.datalayerPageName = data?.datalayerPageName
  }
  if (data?.eventPageType) {
    gbhDataLayerCustom.eventPageType = data?.eventPageType
  }
  if (data?.pageType) {
    gbhDataLayerCustom.pageType = data?.pageType
  }
  if (
    !data?.pageComponentProperties?.description &&
    data?.pageComponentProperties?.dataLayer &&
    data?.pageComponentProperties?.dataLayer[data?.pageComponentProperties?.id]
  ) {
    gbhDataLayerCustom.dataLayer =
      data?.pageComponentProperties?.dataLayer[
        data?.pageComponentProperties?.id
      ]
  }
  tmpData = filterBackEndJson(tmpData)
  const headertmp = filterBackEndJson(header)
  const footertmp = filterBackEndJson(footer)
  const metaContent = data?.metaContent ?? ""
  const homeApplicationJSONSchema =
    data?.pageComponentProperties.id == "home" ? data?.schemaJsonLD : ""
  const canonicalUrl = data?.canonicalUrl ?? ""
  // Pass data to the page via props
  return {
    props: {
      ip,
      tmpData,
      headertmp,
      footertmp,
      metaContent,
      globalScript,
      footerScript,
      topHeadScript,
      analyticsData,
      analyticsScriptClass,
      gbhDataLayerCustom,
      urlPath,
      homeApplicationJSONSchema,
      canonicalUrl,
      seoProperties,
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}
export default Home
